import product1 from "../../assets/alt.png";
import product2 from "../../assets/alt.png";
import product3 from "../../assets/alt.png";
import product4 from "../../assets/alt.png";
import product5 from "../../assets/alt.png";

export const FRAMEOFFERS = [
  {
    id: 1,
    productname: "Exotic Memorable Frame",
    originalprice: 699,
    price: 499,
    productimage: product1,
    productdesc: "Store Your Beautiful memories of your past by our exclusingly customisable options",
  },
  {
    id: 2,
    productname: "Exotic Memorable Frame",
    originalprice: 699,
    price: 499,
    productimage: product2,
    productdesc: "Store Your Beautiful memories of your past by our exclusingly customisable options",
  },
  {
    id: 3,
    productname: "Exotic Memorable Frame",
    originalprice: 699,
    price: 499,
    productimage: product3,
    productdesc: "Store Your Beautiful memories of your past by our exclusingly customisable options",
  },
  {
    id: 4,
    productname: "Exotic Memorable Frame",
    originalprice: 699,
    price: 499,
    productimage: product4,
    productdesc: "Store Your Beautiful memories of your past by our exclusingly customisable options",
  },
  {
    id: 5,
    productname: "Exotic Memorable Frame",
    originalprice: 699,
    price: 499,
    productimage: product5,
    productdesc: "Store Your Beautiful memories of your past by our exclusingly customisable options",
  },
];
