import product1 from "../../assets/alt.png";
import product2 from "../../assets/alt.png";
import product3 from "../../assets/alt.png";
import product4 from "../../assets/alt.png";
import product5 from "../../assets/alt.png";

export const GIFTOFFERS = [
  {
    id: 1,
    productname: "Valentine Cup gift",
    originalprice: 699,
    price: 499,
    productimage: product1,
},
  {
    id: 2,
    productname: "Cute Anime keychain",
    originalprice: 699,
    price: 499,
    productimage: product2,
},
  {
    id: 3,
    productname: "Gift Watches",
    originalprice: 699,
    price: 499,
    productimage: product3,
  },
  {
    id: 4,
    productname: "Product 4",
    originalprice: 699,
    price: 499,
    productimage: product4,
  },
  {
    id: 5,
    productname: "Product 5",
    originalprice: 699,
    price: 499,
    productimage: product5,
  },
];
